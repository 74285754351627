import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Switch, Route } from "react-router-dom";
import FirstEntry from "./pages/FirstEntry";
import SecondEntry from "./pages/SecondEntry";
import ThirdEntry from "./pages/ThirdEntry";
import LavenderSideEntry from "./pages/LavenderSideEntry";
import FirstEntryLark from "./pages/FirstEntryLark";
import LastEntry from "./pages/LastEntry";



import Home from "./pages/Home";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="/maelstrom" element={<LastEntry />} />
          <Route path="/benjamin" element={<LastEntry />} />
          <Route path="/storyteller" element={<LastEntry />} />
          <Route path="/lavender" element={<LastEntry />} />
          <Route path="/absurdity" element={<LastEntry />} />

      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);